export const BUTTON_DATA = [
  {
    id: 'used_btn',
    label: 'Shop used',
    url: '/used-all/',
    dataTrackingId: 'shop_used_link_click',
  },
  {
    id: 'new_btn',
    label: 'Shop new',
    url: '/new-cars-for-sale/',
    dataTrackingId: 'shop_new_link_click',
  },
  {
    id: 'reviews_btn',
    label: 'Reviews',
    url: '/car-reviews/',
    dataTrackingId: 'shop_reviews_link_click',
  },
  {
    id: 'appraise_btn',
    label: 'Appraise my car',
    url: '/appraisal/',
    dataTrackingId: 'shop_appraise_link_click',
  },
];

export const OPEN_DELAY = 300;
export const INPUT_DELAY = 300;
export const LOOKING_FOR_PLACEHOLDER = "Describe what you're looking for";
export const AUTOSIZED_AREA_LENGTH = 200;
export const INPUT_MIN_HEIGHT = 56;
export const LISTBOX_ID = 'search-results-box';
export const CLOSE_RESULTS_LABEL = 'Close search results';
export const CLEAR_INPUT = 'Clear input field';
export const PRIMING_BOX_COPY = 'Try searching by car name, features, or description';
export const CREATIVE_ID = 'edm-entry-homepage-semantic-search';
export const CREATIVE_ID_SEMANTIC = 'edm-entry-semantic-inventory-search';
export const CREATIVE_ID_FILTERS = 'suggested-filters-modal';
export const FOUND_VIN_SCREEN_CREATIVE_ID = 'vdp-found-semantic-inventory-search';
export const ERROR_SCREEN_CREATIVE_ID = 'error-state-semantic-inventory-search';
export const CLICK_OR_TAP_ACTION = 'click or tap action';
export const KEYBOARD_ACTION = 'keyboard action ';
export const SELECT_OPTION = 'Please select an option below';
export const API_LINKS_TYPE = {
  RANKINGS: 'RANKINGS',
  APPRAISAL: 'APPRAISAL',
  COMPARE: 'COMPARE',
  FORUMS: 'FORUMS',
  DEALERSHIP_LANDING: 'DEALERSHIP_LANDING',
  BUILD_AND_PRICE: 'BUILD_AND_PRICE',
  RESEARCH: 'RESEARCH',
};
