import { compact, get, isEmpty, omit, omitBy, values, flatten, uniqBy, isEqual, escapeRegExp } from 'lodash';
import { getCoreUrl, getMakeModelVsMakeModelUrl } from 'site-modules/shared/utils/core-link-constructor';
import { srpLinkBuilderWithParamsConversion } from 'site-modules/shared/utils/srp-link-constructor';
import { getCurrentYear } from 'site-modules/shared/utils/time-util';
import { PUB_STATES } from 'client/constants/pub-states';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
import { API_LINKS_TYPE } from 'site-modules/shared/constants/global-search/global-search';

function getMMTData({ struct }) {
  return flatten(
    [get(struct, 'makeModelTrim'), get(struct, 'makeModel'), get(struct, 'makes'), get(struct, 'other')].filter(
      el => !isEmpty(el)
    )
  );
}

export function getCompareLink({ fastMatcherData }) {
  const { struct } = fastMatcherData;
  const data = getMMTData({ struct });

  if (data.length === 2 && data.every(vehicle => vehicle.make && vehicle.model)) {
    const vehicles = data.map(facets => ({
      make: get(facets, 'make.value'),
      model: get(facets, 'model.value', '').split('|')[1],
      title: compact([get(facets, 'make.name', ''), get(facets, 'model.name')]).join(' '),
    }));

    return {
      title: `${vehicles[0].title} vs. ${vehicles[1].title}`.toLowerCase(),
      url: getMakeModelVsMakeModelUrl(vehicles[0], vehicles[1]),
      selectionType: 'compare models',
    };
  }

  return null;
}

function parseJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

function getBodyTypes(facetsByName) {
  return facetsByName?.find(({ type }) => type === 'bodyType')?.values.map(({ name }) => name);
}

function getEngineTypes(facetsByName) {
  return facetsByName?.find(({ type }) => type === 'engineType')?.values.map(({ name }) => name);
}

function getSrpSelectionType({ options }) {
  const SELECTION_TYPES_AVAILABLE = ['year', 'make', 'model', 'trim', 'bodyType'];
  const selectionType = flatten([
    options.inventorytype.includes(INVENTORY_TYPES_LOWERCASE.NEW)
      ? INVENTORY_TYPES_LOWERCASE.NEW
      : INVENTORY_TYPES_LOWERCASE.USED,
    compact(SELECTION_TYPES_AVAILABLE.map(selType => (options[selType] ? selType : null))),
  ])
    .join(' ')
    .replace('bodyType', 'type');

  return `${selectionType} for sale`;
}

export function getSrpLink({ fastMatcherData, types }) {
  const { struct, delta } = fastMatcherData;
  const parsedDelta = parseJSON(delta);

  if (!delta) {
    return null;
  }

  const deltaInventoryType = get(parsedDelta, 'inventoryType', []);
  const deltaInventoryTypeFiltered = deltaInventoryType.filter(inventoryType =>
    types.includes(inventoryType.toLowerCase())
  );
  const inventoryTypes = isEmpty(deltaInventoryType) ? types : deltaInventoryTypeFiltered;
  if (isEmpty(inventoryTypes)) {
    return null;
  }

  const title = new Set();
  const SPECIAL_FIELDS = ['make', 'model', 'trim', 'inventorytype'];
  let options = { ...omit(parsedDelta, [...SPECIAL_FIELDS, 'inventoryType']) };
  const data = getMMTData({ struct });

  if (options.bodyType) {
    options.bodyTypeName = getBodyTypes(struct.facetsByName);
  }

  if (options.engineType) {
    options.engineTypeName = getEngineTypes(struct.facetsByName);
  }

  if (options.year) {
    options.formattedYear = options.year.replace('-*', '+').replace('*-', '-');
  }

  const joinedEngineTypeNames = options.engineTypeName?.join(', ');
  const joinedBodyTypeNames = options.bodyTypeName?.join(', ');

  if (!isEmpty(data)) {
    const filters = {
      make: new Set(),
      model: new Set(),
      trim: new Set(),
      inventorytype: new Set(),
    };

    data.forEach(facets => {
      inventoryTypes.forEach(type => {
        if (get(facets, `inventoryCount[${type}]`)) {
          filters.inventorytype.add(type);
          filters.make.add(get(facets, 'make.value'));
          filters.model.add(get(facets, 'model.value'));
          filters.trim.add(get(facets, 'trim.value'));
          title.add(
            compact([
              options.formattedYear,
              get(facets, 'make.name', ''),
              get(facets, 'model.name', ''),
              get(facets, 'trim.name', ''),
              joinedEngineTypeNames,
              joinedBodyTypeNames,
            ]).join(' ')
          );
        }
      });
    });
    options = {
      ...options,
      ...SPECIAL_FIELDS.reduce(
        (res, filterName) => ({ ...res, [filterName]: compact(Array.from(filters[filterName])).join(',') }),
        {}
      ),
    };
  } else {
    options = { ...options, inventorytype: inventoryTypes };
    title.add(compact([options.formattedYear, joinedEngineTypeNames, joinedBodyTypeNames]).join(' '));
  }

  const selectionType = getSrpSelectionType({ options });

  if (isEmpty(omitBy(options, val => isEmpty(val))) || !options.inventorytype) {
    return null;
  }

  return {
    title: `${
      types.includes(INVENTORY_TYPES_LOWERCASE.NEW) ? INVENTORY_TYPES_LOWERCASE.NEW : INVENTORY_TYPES_LOWERCASE.USED
    } ${Array.from(title).join(' and ')} for sale`.toLowerCase(),
    url: srpLinkBuilderWithParamsConversion(options),
    selectionType,
  };
}

function getYear({ publicationStates }) {
  const currentYear = getCurrentYear();
  const availableYears = flatten(values(publicationStates));

  if (availableYears.includes(currentYear)) {
    return currentYear;
  }

  return availableYears.length ? Math.max(...availableYears) : currentYear;
}

export function getPricingReviewsLinks({ fastMatcherData }) {
  const { struct, delta } = fastMatcherData;
  const data = getMMTData({ struct });
  const deltaInventoryType = get(parseJSON(delta), 'inventoryType');
  const isNewInventory = deltaInventoryType?.some(inventoryType => inventoryType === INVENTORY_TYPES_LOWERCASE.NEW);

  if (deltaInventoryType && !isNewInventory) {
    return null;
  }

  return uniqBy(
    compact(
      data.map(facets => {
        const makeSlug = get(facets, 'make.value', '');
        const modelSlug = get(facets, 'model.value', '').split('|')[1];
        const publicationStates = get(facets, 'publicationState', {});

        if (!modelSlug || isEqual(Object.keys(publicationStates), [PUB_STATES.USED])) return null;

        const year = parseInt(getYear({ publicationStates }), 10);
        const defaultYear = parseInt(getCurrentYear(), 10);
        const isSingleYear =
          publicationStates?.[PUB_STATES.NEW]?.length === 1 || publicationStates?.[PUB_STATES.NEW_USED]?.length === 1;

        return {
          title: compact([get(facets, 'make.name', ''), get(facets, 'model.name')]).join(' '),
          url: getCoreUrl({
            makeSlug,
            modelSlug,
            year,
            ...(isSingleYear ? {} : { defaultYear }),
          }),
          makeSlug,
          modelSlug,
          year,
          selectionType: `${year >= defaultYear ? 'new' : 'used'} review`,
        };
      })
    ),
    'title'
  );
}

export function getResearchLinks({ fastMatcherData }) {
  const { struct } = fastMatcherData;
  const data = getMMTData({ struct });

  if (getCompareLink({ fastMatcherData })) {
    return null;
  }

  return uniqBy(
    compact(
      data.map(facets => {
        if (!facets?.make?.value) return null;
        return {
          title: `all ${get(facets, 'make.name', '')} models`.toLowerCase(),
          url: `/${get(facets, 'make.value')}/`,
          selectionType: 'make page',
        };
      })
    ),
    'title'
  );
}

export function getApiLinks({ fastMatcherData }) {
  const {
    struct: { links },
  } = fastMatcherData;

  return links?.map(({ url, name }) => ({
    title: name.toLowerCase(),
    url,
    selectionType: 'site links',
  }));
}

export function getAppraisalLinks({ fastMatcherData }) {
  const {
    struct: { links },
  } = fastMatcherData;

  return links
    ?.filter(({ type }) => type === API_LINKS_TYPE.APPRAISAL)
    .map(({ url, name }) => ({
      title: name.toLowerCase(),
      url,
    }));
}

export function isAppraisalLinks({ fastMatcherData }) {
  const {
    struct: { links },
  } = fastMatcherData;
  return !!links?.find(({ type }) => type === API_LINKS_TYPE.APPRAISAL);
}

export function highlightQuery({ userInput, title }) {
  const escapedUserInput = userInput.replaceAll(/[{}]+|\bor\b|\band\b|\bvs\b/g, '');
  const userInputTokens = escapedUserInput
    .split(' ')
    .map(w => w.trim())
    .filter(token => !!token);
  const highlighted = userInputTokens.reduce(
    (result, item) => result.replaceAll(new RegExp(escapeRegExp(item), 'gi'), '{{$&}}'),
    title
  );
  const replacerMap = {
    '{': '<span class="fw-normal">',
    '}': '</span>',
  };

  return Object.keys(replacerMap).reduce(
    (result, key) => result.replace(new RegExp(`${key}+`, 'g'), replacerMap[key]),
    highlighted
  );
}

export function getGlobalSearchLinks({ fastMatcherData }) {
  return isAppraisalLinks({ fastMatcherData })
    ? {
        apiLinks: {
          links: getAppraisalLinks({ fastMatcherData }),
          icon: 'icon-search',
        },
      }
    : {
        pricingReviewsLinks: {
          links: getPricingReviewsLinks({ fastMatcherData }),
          icon: 'icon-compare-arrows',
          label: 'New Pricing & Reviews',
          withThumbnail: true,
          trackingLinks: getPricingReviewsLinks({ fastMatcherData })?.map(({ title }) => ({
            title: `${title} new review`,
          })),
        },
        usedSrpLinks: {
          links: compact([
            getSrpLink({ fastMatcherData, types: [INVENTORY_TYPES_LOWERCASE.USED, INVENTORY_TYPES_LOWERCASE.CPO] }),
          ]),
          icon: 'icon-shopping-cart',
          label: 'Shopping',
        },
        newSrpLinks: {
          links: compact([getSrpLink({ fastMatcherData, types: [INVENTORY_TYPES_LOWERCASE.NEW] })]),
          icon: 'icon-shopping-cart',
          label: 'Shopping',
        },
        compareLinks: {
          links: compact([getCompareLink({ fastMatcherData })]),
          icon: 'icon-compare-arrows',
          label: 'Compare',
        },
        researchLinks: {
          links: getResearchLinks({ fastMatcherData }),
          icon: 'icon-list',
          label: 'Research',
        },
        apiLinks: {
          links: getApiLinks({ fastMatcherData }),
          icon: 'icon-search',
        },
      };
}
