import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { Collapse } from 'site-modules/shared/components/collapse/collapse';
import { LISTBOX_ID, PRIMING_BOX_COPY } from 'site-modules/shared/constants/global-search/global-search';
import { PersonalizedSearchQuery } from 'site-modules/shared/components/inventory/global-search/personalized-search-query/personalized-search-query';
import { GlobalNavigationQuery } from 'site-modules/shared/components/inventory/global-search/global-navigation-query/global-navigation-query';

import './global-navigation-search.scss';

const SEARCH_EDMUNDS = 'Search Edmunds';

export function GlobalNavigationSearch({ isMobile, withLlmSearchDisabled, isSearch42Chal2 }) {
  const [isOpen, setOpen] = useState(false);
  const inputRef = useRef();
  const buttonRef = useRef();

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      inputRef.current?.classList?.toggle('d-none', false);
      inputRef.current?.focus();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  useEffect(() => {
    function toggleScrolling(disableScroll) {
      document.documentElement.classList.toggle('overflow-hidden', !!disableScroll);
    }

    if (!isMobile && isOpen) {
      toggleScrolling(true);
    }

    return () => {
      toggleScrolling();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      inputRef.current.classList.toggle('d-none', true);
    }
  }, [isOpen]);

  const globalSearch = isSearch42Chal2 ? (
    <PersonalizedSearchQuery
      isMobile={isMobile}
      withLlmSearchDisabled={withLlmSearchDisabled}
      isOpen={!isMobile || isOpen}
      onChangeOpenState={setOpen}
      isSearchVisible={isOpen}
      isGlobalNav
      withEnterSubmit
      controlled
    />
  ) : (
    <GlobalNavigationQuery
      isMobile={isMobile}
      withLlmSearchDisabled={withLlmSearchDisabled}
      isOpen={!isMobile || isOpen}
      onSetOpen={setOpen}
      isSearchVisible={isOpen}
    />
  );

  return (
    <div
      className={classnames('global-navigation-search pos-r w-100', {
        'd-flex justify-content-end': isMobile,
      })}
      style={{
        ...(isMobile ? { justifyContent: 'flex-end' } : {}),
        paddingLeft: '1.5rem',
        ...(!isMobile ? { paddingRight: '1.5rem' } : {}),
      }}
    >
      <Button
        className="search-edmunds-button pos-r w-100 px-0_75 py-0_5 bg-white text-transform-none fw-normal text-cool-gray-50 size-16 border-0 d-flex align-items-center align-self-center"
        style={{
          fontSize: '1rem',
          lineHeight: '1.375rem',
          height: '42px',
          maxWidth: '200px',
          borderRadius: '4px',
          padding: '0.5rem 0.75rem',
          alignSelf: 'center',
        }}
        onClick={handleClick}
        innerRef={buttonRef}
        aria-expanded={isOpen}
        aria-haspopup="dialog"
        aria-controls={LISTBOX_ID}
      >
        <span className="d-inline-block">
          <img src={getStaticImageUrl('/icons/magic-search.svg')} alt="" decoding="async" loading="lazy" />
        </span>
        <span
          className="pos-a left-0 right-0 ms-3 me-0_75 text-start text-truncate"
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            marginLeft: '3rem',
            marginRight: '0.75rem',
            overflow: 'hidden',
          }}
        >
          {SEARCH_EDMUNDS}
        </span>
      </Button>

      {/* ios hack to open keyboard in drawer */}
      <input aria-hidden type="text" ref={inputRef} className="visually-hidden" tabIndex={-1} />

      {!isMobile && (
        <Collapse
          isOpen={isOpen}
          transition="height 300ms linear"
          id={LISTBOX_ID}
          collapseHeight="50px"
          hideCollapsedInteractiveElements={false}
          addState
          role="dialog"
          aria-label={PRIMING_BOX_COPY}
          className="global-nav-search-collapse"
          style={{ position: 'fixed', zIndex: 1 }}
        >
          {globalSearch}
        </Collapse>
      )}
      {isMobile && globalSearch}
    </div>
  );
}

GlobalNavigationSearch.propTypes = {
  isMobile: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
  isSearch42Chal2: PropTypes.bool,
};

GlobalNavigationSearch.defaultProps = {
  isMobile: false,
  withLlmSearchDisabled: false,
  isSearch42Chal2: false,
};
