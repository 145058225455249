import React, { memo, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';
import classnames from 'classnames';
/* Utils */
import { getGlobalSearchLinks, highlightQuery } from 'site-modules/shared/utils/inventory/global-search-links';
import { fireSearchLinkTracking } from 'site-modules/shared/utils/inventory/global-search-tracking';
/* Components */
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { GlobalSearchThumbnail } from 'site-modules/shared/components/inventory/global-search/global-search-thumbnail/global-search-thumbnail';
import { SpeculationRule } from 'site-modules/shared/components/speculation-rule/speculation-rule';
import { Link } from 'site-modules/shared/components/link/link';

const PRERENDER_LINKS_NUM = 1;

export const GlobalSearchLinks = memo(({ query, fastMatcherData, creativeId, isMobile }) => {
  const globalSearchLinks = useMemo(() => getGlobalSearchLinks({ fastMatcherData }), [fastMatcherData]);

  const pricingReviewsLinks = globalSearchLinks.pricingReviewsLinks?.links;
  const speculationLinks = useMemo(
    () => ({
      prerender: pricingReviewsLinks?.slice(0, PRERENDER_LINKS_NUM)?.map(({ url }) => url),
      prefetch: pricingReviewsLinks?.slice(PRERENDER_LINKS_NUM)?.map(({ url }) => url),
    }),
    [pricingReviewsLinks]
  );

  const handleClick = useCallback(
    ({ selectionType, title }) => () =>
      fireSearchLinkTracking({
        input: query,
        fastMatcherData,
        value: title,
        selectionType,
        mmtSearchId: fastMatcherData?.trace?.uuid,
        creativeId,
      }),
    [query, fastMatcherData, creativeId]
  );

  return (
    <Fragment>
      <ul className="list-unstyled mb-0">
        {map(
          globalSearchLinks,
          ({ links, icon, label, withThumbnail }) =>
            !isEmpty(links) &&
            links.map(({ title, url, makeSlug, modelSlug, year, selectionType }) => (
              <li key={`${title}-${url}`}>
                <Link
                  to={url}
                  className={classnames('text-decoration-none d-flex w-100 global-search-link py-0_75 rounded-8', {
                    'px-0_5': !isMobile,
                  })}
                  onClick={handleClick({ selectionType, title })}
                >
                  {withThumbnail ? (
                    <GlobalSearchThumbnail make={makeSlug} model={modelSlug} year={year} />
                  ) : (
                    <div className="gray-circle d-flex align-items-center justify-content-center align-self-center rounded-circle me-1 bg-cool-gray-90 text-cool-gray-40">
                      <span className={classnames('icon medium', icon)} aria-hidden />
                    </div>
                  )}
                  <div className={classnames({ 'd-flex align-items-center': !label })}>
                    <span className="fw-bold d-block">
                      <ContentFragment componentToUse="span">
                        {highlightQuery({ userInput: query, title })}
                      </ContentFragment>
                    </span>
                    {!!label && <span className="medium text-cool-gray-40">{label}</span>}
                  </div>
                </Link>
              </li>
            ))
        )}
      </ul>
      {!!speculationLinks.prerender?.length && (
        <SpeculationRule speculationTimeout={750} urls={speculationLinks.prerender} />
      )}
      {!!speculationLinks.prefetch?.length && (
        <SpeculationRule action="prefetch" speculationTimeout={1250} urls={speculationLinks.prefetch} />
      )}
    </Fragment>
  );
});

GlobalSearchLinks.displayName = 'GlobalSearchLinks';

GlobalSearchLinks.propTypes = {
  query: PropTypes.string,
  creativeId: PropTypes.string,
  isMobile: PropTypes.bool,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }).isRequired,
};

GlobalSearchLinks.defaultProps = {
  query: '',
  creativeId: '',
  isMobile: false,
};
